import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import HeaderWhitenav from "../components/header-whitenav"
import Shortcut from "../components/offer/shortcut"
import SEO from "../components/seo";

const Pricelist = ({ children, offerTitle }) => {
  const { strapiOffer } = useStaticQuery(graphql`
      query {
          strapiOffer {
              categories {
                  title
                  slug
                  subcategories {
                      title
                      slug
                  }
              }
              entries {
                  title
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
                  text
                  procedures {
                      title
                      price
                      isPromo
                      promo
                      old
                  }
                  leftHeader
                  leftContent
                  rightHeader
                  rightContent
                  category
              }
          }
      }
  `)

  function sanitize(text: string) {
    return text
      .replace(/([^>\\r\\n]?)(\\r\\n|\\n\\r|\\r|\\n)/g, "<br />")
      .replace(/(\\"|\\_|\\t)/g, "")
  }

  const offers = strapiOffer.entries

  const { categories } = strapiOffer

  const categoriesJsx = categories.map((category: any, index: number) => {
    const subcategoriesJsx = category.subcategories.map((subcategory: any, subindex: number) => {
      const procedureLinksJsx = offers.filter((offer: any) => {
        return offer.title.trim() === subcategory.title.trim()
      })[0]
        .procedures
        .map((procedure: any, index: number) => {
          return (
            <li key={index}>
              <Link to={`/oferta/${category.slug}/${subcategory.slug}`}>
                {procedure.title}
              </Link>
            </li>
          )
        })

      if(procedureLinksJsx.length === 0) return;

      return (
        <li key={subindex}>
          <a href={`#`} className={'subkat_link'}>
            {subcategory.title}
          </a>
          <ul className={'hide-not-important'}>
            {procedureLinksJsx}
          </ul>
        </li>
      )
    })

    return (
      <div className="category-box" key={index}>
        <div className="row">
          <div className="large-3 medium-1 small-2 columns">
            <h3 className="kreska margin-0">0{index + 1}</h3>
          </div>
          <div className="large-9 medium-11 small-10 columns">
            <p className="normal border-bottom border-bottom-black h6">{category.title} <i
              className="fa fa-angle-right" aria-hidden="true"></i></p>
          </div>
        </div>

        <div className={"category-menu no-hide"} style={{display: 'none'}}>
          <div className="row">
            <div
              className="large-9 large-offset-3 medium-11 medium-offset-1 small-10 small-offset-2 columns end">
              <ul className="clear-ul">
                {subcategoriesJsx}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  })

  const proceduresJsx = categories.map((category: any, index: number) => {
    const subcategoriesJsx = category.subcategories.map((subcategory: any, subindex: number) => {
      const procedureLinksJsx = offers.filter((offer: any) => {
        return offer.title.trim() === subcategory.title.trim()
      })[0]
        .procedures
        .map((procedure: any, index: number) => {
          return (
            <div className={procedure.isPromo ? "zabieg is-promo" : "zabieg"} key={index}>
              <div className="row">
                <div className="large-7 medium-7 small-6 columns">
                  <p className="normal h3">
                    <Link to={`/oferta/${category.slug}/${subcategory.slug}`} className={'black'}>
                      {procedure.title}
                    </Link>
                  </p>
                </div>
                <div className="large-5 medium-5 small-6 columns text-right">
                  {procedure.isPromo &&
                  <p className={"red"}>{procedure.promo}</p>
                  }
                  {procedure.isPromo &&
                  <p className={"old"}>{procedure.old}</p>
                  }
                  {!procedure.isPromo &&
                  <p className="extras">{procedure.price}</p>
                  }
                </div>
              </div>
            </div>
          )
        })

      return (
        <div className={subcategory.slug}>
          <h2 className="zabieg_sub h5">
            {subcategory.title}
          </h2>
          {procedureLinksJsx}
        </div>
      )
    })

    const categoryTitle = 'kat_' + category.slug

    return (
      <div className={"all kat_zabieg " + categoryTitle}>
        {subcategoriesJsx}
      </div>
    )
  })

  return (
    <Layout>
      <HeaderWhitenav />
      <SEO
        title={'Cennik | Dent&Beauty Świdnica'}
        description={'Zobacz ceny wszystkich usług oferowanych w naszej klinice.'}
      />
      <div id="pagewrapper">
        <div className="row">
          <div className="large-9 large-push-3 medium-12 small-12 columns">
            <div className="row">
              <div className="large-9 medium-12 small-12 columns end">
                <Shortcut />
                {children}
                <section className="section padding-top60 wow fadeInUp" data-wow-duration="1.5s" style={{visibility: 'hidden', animationDuration: '1.5s', animationName: 'fadeInUp'}}>
                  <div className="row">
                    <div className="large-5 medium-12 small-12 columns">
                      <h1 className="border-bottom h2">Cennik</h1>
                    </div>
                    <div className="large-7 medium-12 small-12 columns text-left large-text-right">
                      <form className="margin-0">
                        <p className="hide-for-large">
                          Kliknij w zabieg, by dowiedzieć się&nbsp;więcej.
                        </p>
                        <div className="row">
                          <div className="large-5 medium-5 small-5 columns text-right"><label htmlFor="category">wybierz kategorię:</label></div>
                          <div className="large-7 medium-7 small-7 columns">
                            <select className="select-chosen wide" id="category" style={{display: 'none'}}>
                              <option value="kat_zabieg">Wszystkie</option>
                              <option value="kat_stomatologia">Stomatologia</option>
                              <option value="kat_medycyna-estetyczna">Medycyna estetyczna</option>
                              <option value="kat_dietetyka">Dietetyka</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {proceduresJsx}
                </section>
              </div>
            </div>
          </div>
          <div className="large-3 large-pull-9 medium-12 small-12 columns wow fadeInUp show-for-large"
               data-wow-duration="1.5s" style={{ visibility: "hidden" }}>
            <Link to="/cennik#is-promo" className="przecenione border-bottom border-bottom-black show-for-large">Pokaż <span
              className="red">przecenione</span> oferty</Link>
            {categoriesJsx}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Pricelist
